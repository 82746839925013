import ms from 'ms';
import { CookieSetOptions } from 'universal-cookie';

export const API_URL = process.env.NEXT_PUBLIC_API_URL || process.env.API_URL;

export const FILE_LINKS = {
  privacyPolicyLink:
    'https://shepherd-public.s3.us-west-1.amazonaws.com/Shepherd+Labs+-+Privacy+Policy.pdf',
  termsOfUseLink:
    'https://shepherd-public.s3.us-west-1.amazonaws.com/Shepherd+Labs+-+Terms+of+Use.pdf',
};
export const INTERCOM_APP_ID = 'zt1oteik';
export const INTERCOM_APP_SECRET = 'jDYfd7iCJZ3Qu06eEuy68u8cMbbjzH3Y2ck-tTNl';

export const SEND_TO_ANALYTICS = process.env.NEXT_PUBLIC_SEND_TO_ANALYTICS;

export const CURRENT_URL =
  // eslint-disable-next-line no-nested-ternary
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'https://shepherd.insure'
    : process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
    ? process.env.NEXT_PUBLIC_VERCEL_URL
    : `http://localhost:8000`;

export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};

export const SIDEBAR_WIDTH = 200;
export const IS_DEV = process.env.NODE_ENV !== 'production';
export const IS_PREVIEW =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' || process.env.VERCEL_ENV === 'preview';
export const IS_PRODUCTION =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' || process.env.VERCEL_ENV === 'production';
export const IS_STAGING =
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'staging' ||
  process.env.VERCEL_GIT_COMMIT_REF === 'staging';
export const IS_CANARY =
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'canary' ||
  process.env.VERCEL_GIT_COMMIT_REF === 'canary';
export const IS_AUDIT = process.env.NEXT_PUBLIC_IS_AUDIT === 'true';

export const DATE_FORMAT = 'M/D/YYYY';
export const DATE_PARSE = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'M/DD/YYYY hh:mm A';
export const YEAR_FORMAT = 'YYYY';

export const COOKIE_NAME = 'jwt2';

const DOMAIN_VERCEL_ENV = {
  production: { domain: 'shepherd.insure' },
  preview: { domain: 'shepherd.insure' },
  development: { domain: 'shepherd.insure' },
};

export const COOKIE_OPTIONS: CookieSetOptions = {
  path: '/',
  maxAge: ms('2 days'),
  ...(DOMAIN_VERCEL_ENV[process.env.NEXT_PUBLIC_VERCEL_ENV as keyof typeof DOMAIN_VERCEL_ENV] ||
    {}),
};
