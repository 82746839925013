import { useApolloClient } from '@apollo/client';
import { showNotification } from '@mantine/notifications';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useCallback, useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDebounce } from 'react-use';
import { COOKIE_OPTIONS, COOKIE_NAME } from '../constants';
import {
  CreateSessionMutation,
  SessionQuery,
  useCreateSessionMutation,
} from '../graphql/operations/users.generated';
import { OrganizationType, UserRole } from '../graphql/types.generated';
import onApolloError from '../utils/utils';
import { SessionContext } from '../components/SessionProvider';

export const useSession = (): {
  session: SessionQuery['session'];
  loading: boolean;
  isAdmin: boolean;
} => {
  const { session, loading } = useContext(SessionContext);
  // @ts-expect-error
  return { session, loading, isAdmin: session?.user?.role === UserRole.Admin };
};

export const useAlreadyLoggedIn = () => {
  const [disabled, setDisabled] = useState(false);
  const router = useRouter();
  const { session } = useSession();

  useDebounce(
    () => {
      if (disabled) {
        return null;
      }

      if (session?.organization?.type === OrganizationType.Brokerage) {
        router.push(`/u`);

        return showNotification({ message: 'Already logged in', color: 'orange' });
      }

      if (session?.organization?.type === OrganizationType.Applicant) {
        router.push(`/a/${session?.organization?.id}/applications`);

        return showNotification({ message: 'Already logged in', color: 'orange' });
      }

      return null;
    },
    100,
    [router, session?.organization?.id, session?.organization?.type, disabled]
  );

  const setDisabledCallback = useCallback(() => setDisabled(true), []);

  return setDisabledCallback;
};

export const useLogout = () => {
  const client = useApolloClient();
  const router = useRouter();
  const [, , removeCookie] = useCookies();

  const logout = useCallback(() => {
    router.push('/login');
    removeCookie(COOKIE_NAME, COOKIE_OPTIONS);

    client.clearStore();
  }, [client, removeCookie, router]);

  return logout;
};

export const useCreateSession = (
  onSuccess: (session: CreateSessionMutation['createSession']['session']) => void,
  redirect = true
) => {
  const router = useRouter();
  const [, setCookie] = useCookies();
  const userRedirect = useCallback(
    (session: CreateSessionMutation['createSession']['session']) => {
      if (router.query.returnTo) {
        return router.replace(router.query.returnTo as string);
      }

      if (session && session?.organization?.type) {
        if (session?.organization?.type === OrganizationType.Brokerage) {
          return router.push(`/u`);
        }

        if (session?.organization?.type === OrganizationType.Applicant) {
          return router.push(`/a/${session?.organization?.id}/applications`);
        }

        return router.push('/?error=Invalid organization');
      }

      return null;
    },
    [router]
  );
  const [createSessionMutation] = useCreateSessionMutation({
    onCompleted: ({ createSession }) => {
      if (createSession?.session?.token) {
        setCookie(COOKIE_NAME, createSession?.session?.token, COOKIE_OPTIONS);

        Sentry.setUser({
          email: createSession?.session?.user?.email,
          id: createSession?.session?.user?.id,
          organization: createSession?.session?.organization?.name,
        });
      }

      if (onSuccess) {
        return onSuccess(createSession?.session);
      }

      if (redirect) {
        return userRedirect(createSession?.session);
      }

      return null;
    },
    onError: onApolloError('Verification code is incorrect. Please try again'),
  });

  return [createSessionMutation];
};
