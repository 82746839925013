import type { NextSeoProps } from 'next-seo';

const siteTitle = 'Shepherd';
const siteDescription = 'Tech-enabled insurance for commercial construction';

// eslint-disable-next-line import/prefer-default-export
export const defaultSeo: NextSeoProps = {
  title: siteTitle,
  description: siteDescription,
  openGraph: {
    title: siteTitle,
    description: siteDescription,
    type: 'website',
    locale: 'en_US',
    url: 'https://shepherd.insure',
    site_name: 'Shepherd',
    images: [
      {
        url: 'https://shepherd.insure/images/preview.jpg',
        width: 800,
        height: 600,
        alt: 'Shepherd',
      },
    ],
  },
};

export const makeSeo = ({
  title = siteTitle,
  description = siteDescription,
  imageUrl = 'https://shepherd.insure/images/preview.jpg',
}: {
  title?: string;
  description?: string;
  imageUrl?: string;
}) => {
  return {
    title,
    description,
    openGraph: {
      type: 'website',
      locale: 'en_US',

      title,
      description,
      images: [{ url: imageUrl, width: 600, height: 300, alt: title }],
      site_name: 'Shepherd',
    },
  };
};
