import { snakeCase } from 'lodash';

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const humanizeString = (string: string) => {
  const newString = snakeCase(string)
    .toLowerCase()
    .replace(/[_-]+/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .trim();

  return newString.charAt(0).toUpperCase() + newString.slice(1);
};

export const humanizeStringFirstUpper = (string: string) => {
  return humanizeString(string)
    .split(' ')
    .map((item) => capitalizeFirstLetter(item))
    .join(' ');
};
export default humanizeString;
