export const getEnv = () => {
  if ((process.env.VERCEL_GIT_COMMIT_REF || process.env.VERCEL_GIT_COMMIT_REF) === 'main') {
    return 'production';
  }

  if ((process.env.VERCEL_GIT_COMMIT_REF || process.env.VERCEL_GIT_COMMIT_REF) === 'staging') {
    return 'staging';
  }

  if ((process.env.VERCEL_GIT_COMMIT_REF || process.env.VERCEL_GIT_COMMIT_REF) === 'canary') {
    return 'canary';
  }

  return process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV;
};
